@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/inter-thin.ttf') format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/inter-extralight.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/inter-light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/inter-regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/inter-medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/inter-semibold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/inter-bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/inter-extrabold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/inter-black.ttf') format('truetype');
  font-weight: 900;
}

/* EDITOR FONTS */

@font-face {
  font-family: 'AG University';
  src: url('assets/fonts/editor-fonts/ag-university-regular.ttf')
    format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Amazone';
  src: url('assets/fonts/editor-fonts/amazone-regular.ttf') format('ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Arima Madurai';
  src: url('assets/fonts/editor-fonts/arima-madurai-regular.ttf')
    format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Arima Madurai';
  src: url('assets/fonts/editor-fonts/arima-madurai-bold.ttf')
    format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Bitter';
  src: url('assets/fonts/editor-fonts/bitter-regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Bitter';
  src: url('assets/fonts/editor-fonts/bitter-italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Bitter';
  src: url('assets/fonts/editor-fonts/bitter-bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Book Antiqua';
  src: url('assets/fonts/editor-fonts/book-antiqua-regular.ttf')
    format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Book Antiqua';
  src: url('assets/fonts/editor-fonts/book-antiqua-italic.ttf')
    format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Book Antiqua';
  src: url('assets/fonts/editor-fonts/book-antiqua-bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Book Antiqua';
  src: url('assets/fonts/editor-fonts/book-antiqua-bolditalic.ttf')
    format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Bookman Old Style';
  src: url('assets/fonts/editor-fonts/bookman-old-style-regular.ttf')
    format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Bookman Old Style';
  src: url('assets/fonts/editor-fonts/bookman-old-style-italic.ttf')
    format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Bookman Old Style';
  src: url('assets/fonts/editor-fonts/bookman-old-style-bold.ttf')
    format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Bookman Old Style';
  src: url('assets/fonts/editor-fonts/bookman-old-style-bolditalic.ttf')
    format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Century Gothic';
  src: url('assets/fonts/editor-fonts/century-gothic-regular.ttf')
    format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Century Gothic';
  src: url('assets/fonts/editor-fonts/century-gothic-italic.ttf')
    format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Century Gothic';
  src: url('assets/fonts/editor-fonts/century-gothic-bold.ttf')
    format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Century Gothic';
  src: url('assets/fonts/editor-fonts/century-gothic-bolditalic.ttf')
    format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Charm';
  src: url('assets/fonts/editor-fonts/charm-regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Charm';
  src: url('assets/fonts/editor-fonts/charm-bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Cormorant';
  src: url('assets/fonts/editor-fonts/cormorant-regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Cormorant';
  src: url('assets/fonts/editor-fonts/cormorant-italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Cormorant';
  src: url('assets/fonts/editor-fonts/cormorant-bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Cormorant';
  src: url('assets/fonts/editor-fonts/cormorant-bolditalic.ttf')
    format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Dancing Script';
  src: url('assets/fonts/editor-fonts/dancing-script-regular.ttf')
    format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Dancing Script';
  src: url('assets/fonts/editor-fonts/dancing-script-bold.ttf')
    format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'English';
  src: url('assets/fonts/editor-fonts/english-157-regular.ttf')
    format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'FG Jayne Print';
  src: url('assets/fonts/editor-fonts/fg-jayne-print-regular.ttf')
    format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Gaeilge';
  src: url('assets/fonts/editor-fonts/gaeilge-regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Goudy Catalog';
  src: url('assets/fonts/editor-fonts/goudy-catalog-regular.ttf')
    format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Goudy Old Style';
  src: url('assets/fonts/editor-fonts/goudy-old-style-regular.ttf')
    format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Goudy Old Style';
  src: url('assets/fonts/editor-fonts/goudy-old-style-italic.ttf')
    format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Goudy Old Style';
  src: url('assets/fonts/editor-fonts/goudy-old-style-bold.ttf')
    format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Kalam';
  src: url('assets/fonts/editor-fonts/kalam-regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Kalam';
  src: url('assets/fonts/editor-fonts/kalam-bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Lato';
  src: url('assets/fonts/editor-fonts/lato-regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('assets/fonts/editor-fonts/lato-italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('assets/fonts/editor-fonts/lato-bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Lato';
  src: url('assets/fonts/editor-fonts/lato-bolditalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Liberty';
  src: url('assets/fonts/editor-fonts/liberty-regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Lobster Two';
  src: url('assets/fonts/editor-fonts/lobster-two-regular.ttf')
    format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Lobster Two';
  src: url('assets/fonts/editor-fonts/lobster-two-italic.ttf')
    format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Lobster Two';
  src: url('assets/fonts/editor-fonts/lobster-two-bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Lobster Two';
  src: url('assets/fonts/editor-fonts/lobster-two-bolditalic.ttf')
    format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Lora';
  src: url('assets/fonts/editor-fonts/lora-regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Lora';
  src: url('assets/fonts/editor-fonts/lora-italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Lora';
  src: url('assets/fonts/editor-fonts/lora-bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Lora';
  src: url('assets/fonts/editor-fonts/lora-bolditalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Merienda';
  src: url('assets/fonts/editor-fonts/merienda-regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Merienda';
  src: url('assets/fonts/editor-fonts/merienda-bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Mistral';
  src: url('assets/fonts/editor-fonts/mistral-regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Monotype Corsiva';
  src: url('assets/fonts/editor-fonts/monotype-corsiva-regular.ttf')
    format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Monterey';
  src: url('assets/fonts/editor-fonts/monterey-regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Murray Hill';
  src: url('assets/fonts/editor-fonts/murray-hill-regular.ttf')
    format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Murray Hill';
  src: url('assets/fonts/editor-fonts/murray-hill-bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Nuptial';
  src: url('assets/fonts/editor-fonts/nuptial-regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Oleo Script';
  src: url('assets/fonts/editor-fonts/oleo-script-regular.ttf')
    format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Oleo Script';
  src: url('assets/fonts/editor-fonts/oleo-script-bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Papyrus';
  src: url('assets/fonts/editor-fonts/papyrus-regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Park Avenue';
  src: url('assets/fonts/editor-fonts/park-avenue-regular.ttf')
    format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Piranesi It';
  src: url('assets/fonts/editor-fonts/piranesi-it-regular.ttf')
    format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Pristina';
  src: url('assets/fonts/editor-fonts/pristina-regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Ribbon';
  src: url('assets/fonts/editor-fonts/ribbon-131-regular.ttf')
    format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Ruthie';
  src: url('assets/fonts/editor-fonts/ruthie-regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Scriptina';
  src: url('assets/fonts/editor-fonts/scriptina-regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Shelley Allegro';
  src: url('assets/fonts/editor-fonts/shelley-allegro-regular.ttf')
    format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Shelley Andante';
  src: url('assets/fonts/editor-fonts/shelley-andante-regular.ttf')
    format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Tangerine';
  src: url('assets/fonts/editor-fonts/tangerine-regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Tangerine';
  src: url('assets/fonts/editor-fonts/tangerine-bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Times New Roman';
  src: url('assets/fonts/editor-fonts/times-new-roman-regular.ttf')
    format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Times New Roman';
  src: url('assets/fonts/editor-fonts/times-new-roman-italic.ttf')
    format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Times New Roman';
  src: url('assets/fonts/editor-fonts/times-new-roman-bold.ttf')
    format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Times New Roman';
  src: url('assets/fonts/editor-fonts/times-new-roman-bolditalic.ttf')
    format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Vivaldi';
  src: url('assets/fonts/editor-fonts/vivaldi-regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Wedding Text';
  src: url('assets/fonts/editor-fonts/wedding-text-regular.ttf')
    format('truetype');
  font-weight: normal;
}

/* FOREIGN LANGUAGE EDITOR FONTS */

@font-face {
  font-family: 'Abyssinica SIL';
  font-feature-settings: 'cv32' 1;
  src: url('assets/fonts/editor-fonts/abyssinica-sil-regular.ttf')
    format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'David Libre';
  src: url('assets/fonts/editor-fonts/david-libre-regular.ttf')
    format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'David Libre';
  src: url('assets/fonts/editor-fonts/david-libre-bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('assets/fonts/editor-fonts/frank-ruhl-libre-regular.ttf')
    format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('assets/fonts/editor-fonts/frank-ruhl-libre-bold.ttf')
    format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Heebo';
  src: url('assets/fonts/editor-fonts/heebo-regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Heebo';
  src: url('assets/fonts/editor-fonts/heebo-bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Mirza';
  src: url('assets/fonts/editor-fonts/mirza-regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Mirza';
  src: url('assets/fonts/editor-fonts/mirza-bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Noto Sans KR';
  src: url('assets/fonts/editor-fonts/noto-sans-kr-regular.otf')
    format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'Noto Sans KR';
  src: url('assets/fonts/editor-fonts/noto-sans-kr-bold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Noto Serif SC';
  src: url('assets/fonts/editor-fonts/noto-serif-sc-regular.otf')
    format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'Noto Serif SC';
  src: url('assets/fonts/editor-fonts/noto-serif-sc-bold.otf')
    format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Noto Serif TC';
  src: url('assets/fonts/editor-fonts/noto-serif-tc-regular.otf')
    format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'Noto Serif TC';
  src: url('assets/fonts/editor-fonts/noto-serif-tc-bold.otf')
    format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/editor-fonts/open-sans-regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/editor-fonts/open-sans-italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/editor-fonts/open-sans-bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/editor-fonts/open-sans-bolditalic.ttf')
    format('truetype');
  font-weight: bold;
  font-style: italic;
}
